import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Agecolumns from "../../blocks/agecolumns";

export const data = graphql`query ImageQuery {
  banner: file(relativePath: {eq: "SVG/home-content-banner.svg"}) {
    publicURL
  }
  bird: file(relativePath: {eq: "SVG/home-bird.svg"}) {
    publicURL
  }
  teachersguide: file(relativePath: {eq: "en/guide/TeachersGuide.pdf"}) {
    publicURL
  }
}`;

const ageGroup = [
    {
        image: 'SVG/home-5-7.svg',
        ageGroup: '5-7',
        resourcesLink: '/en/5-7/handbook/',
        resourcesLinkText: 'Get started',
        glossaryLink: '/en/vocabulary/',
        glossaryLinkText: 'Vocabulary',
    },
    {
        image: 'SVG/home-7-9.svg',
        ageGroup: '7-9',
        resourcesLink: '/en/7-9/handbook/',
        resourcesLinkText: 'Get started',
        glossaryLink: '/en/vocabulary/',
        glossaryLinkText: 'Vocabulary',
    },
    {
        image: 'SVG/home-9-11.svg',
        ageGroup: '9-11',
        resourcesLink: '/en/9-11/handbook/',
        resourcesLinkText: 'Get started',
        glossaryLink: '/en/vocabulary/',
        glossaryLinkText: 'Vocabulary',
    }
]

const IndexPage = ({ data }) => {
    return (
        <Layout lang={'cy'} langURL={'/cy/'}>
            <Seo title="Welcome Teachers!" description="All the information you need to teach your class"
                 lang={'en-GB'}/>
            <section className={'home-banner'} role={'banner'}>
                <Container fluid={true} bsPrefix={'wrap'} className={'h-100'}>
                    <Row className={'h-100'}>
                        <div className={'home-banner--content offset-7 d-flex h-100'}>
                            <p className={'home-banner--content__text my-auto'}>
                                Learning activity ideas, support resources and helpful tools for teachers!
                            </p>
                            {/*
                            <p className={'home-banner--content__text'}>Complete tasks with your class to enter a prize
                                draw!*</p>
                            <p>
                                <Link className={'home-banner--button'} to={'/en/resources/'}>
                                    Find out more
                                </Link>
                            </p>
                            */}
                        </div>
                    </Row>
                </Container>
            </section>
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-5'}>
                            <h1 className={'content--h1'}>Your Teacher’s hub has been created with you in mind!</h1>
                            <p>At Principality Building Society we realise that teaching financial education isn’t easy and that useful support materials can be hard to find, so we wanted to help.</p>
                            <p>This website offers resources, lesson plans and curriculum support to help you deliver financial education in a fun and engaging way and help promote responsible attitudes to money matters.</p>
                            <p>There are full financial education programmes, as well as single activities and resources, to support your existing financial education. Download the <a href={data.teachersguide.publicURL} className={'content--home--text--link'} target={'_blank'} rel={'noreferrer'}>Teacher’s guide</a> for more information.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'content--banner pb-5'}>
                            <a href={data.teachersguide.publicURL} target={'_blank'} rel={'noreferrer'}>
                                <img className={'w-100'} alt={'Click here for resources'} loading={'lazy'}
                                     src={data.banner.publicURL}/>
                            </a>
                        </Col>
                    </Row>
                    {/*
                    <Row>
                        <Col xs={12}>
                            <div className={'content--home--blurb p-5'}>
                                <p className={'m-0'}>We understand that teaching financial education can be hard and support can be difficult to find. At Principality Building Society we want to help. This website has resources, lesson plans and curriculum support to help you deliver financial education in a fun and engaging way. You can choose to download materials for the age group that you are engaged with as individual sessions or you can use the Teacher’s guide to try our connected learner journey through different topics and learning objectives.</p>*
                            </div>
                        </Col>
                    </Row>
                    */}
                    <Row>
                        <Col xs={12} className={'content--home--column--bird'}>
                            <h2>Which age group do you teach?</h2>
                            <p>Access engaging learning activity ideas, support resources and helpful teacher tools. You can choose to download materials for each age group as individual sessions or you can use the Teacher's guide to try our connected learner journey through different topics and learning objectives.</p>
                            <p>Check out the activities and resources available for your chosen age range or just download the accompanying vocabulary list.</p>
                            <img alt={'Little bird'} loading={'lazy'} src={data.bird.publicURL}
                                 style={{width: '60px'}}/>
                        </Col>
                        {ageGroup.map((val, idx) => (
                            <Agecolumns key={idx} image={val.image} ageGroup={val.ageGroup}
                                        resourcesLink={val.resourcesLink} resourcesLinkText={val.resourcesLinkText}
                                        glossaryLink={val.glossaryLink} glossaryLinkText={val.glossaryLinkText} newWindow={false}/>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p>Download lesson plans, handouts and resources for your class to help support your
                                students’ financial education journey, or view the vocabulary list covered at this age
                                group. </p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    )
}

export default IndexPage